<template>
  <ag-card-container centered title="Flowmem Atelier (v2.0.4)">
    <v-img
      src="/images/logo-Polymem.png"
      class="floating-logo"
      contain
    />
    <v-row>
      <v-col cols="8" offset="2">
        <login-form :loading="loading" @send="callLoading" />
      </v-col>
    </v-row>
  </ag-card-container>
</template>

<script>
import { mapActions } from 'vuex'
import AgCardContainer from 'src/components/common/AgCardContainer'
import LoginForm from '../components/LoginForm'

export default {
  name: 'LoginContainer',
  components: {
    AgCardContainer,
    LoginForm,
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    ...mapActions('me', {
      login: 'login',
    }),
    async callLoading (credentials) {
      this.loading = true
      try {
        await this.login(credentials)
      }
      finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
  .floating-logo {
    opacity: 1;
    position: absolute;
    height: 130px;
    top: -125px;
    left: 0px;
  }
</style>
